import React from 'react'
import PropTypes from 'prop-types'

import { ThemeProvider } from 'styled-components'

import { theme } from '../constants/ui/theme'

import GlobalStyles from '../styles/GlobalStyles'
import { PageLayout } from '../styles/PageLayout'

/**
 * The page layout including all global styles and theme provider,
 * used as a wrapper in gatsby-browser and gatsby-ssr.
 * @param {ComponentProps} props
 * @param {ChildNode} props.children
 * @returns {StatelessComponent}
 */
const Page = ({ children }) => (
  <>
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <PageLayout>{children}</PageLayout>
    </ThemeProvider>
  </>
)

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Page
