const breakpoints = {
  screenXS: '360px',
  screenSM: '672px',
  screenMD: '992px',
  screenLG: '1200px',
  screenL: '1440px',
  screenXL: '1700px',
  screenXXL: '1900px',
}

export default breakpoints
