import styled from 'styled-components'

import breakpoints from '../constants/ui/breakpoints'

export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  color: ${({ theme }) => theme.textPrimary};
  width: 100%;
  overflow-x: hidden;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap};
  width: 100%;
  min-height: ${({ minHeight }) => minHeight};
  padding: ${({ padding }) => padding ?? '0 6vw'};
  margin: 0 auto;

  @media (max-width: ${breakpoints.screenLG}) {
    flex-direction: ${({ columnReverse, tabletColumn }) => (columnReverse ? 'column-reverse' : tabletColumn ? 'column' : 'row')};
    justify-content: ${({ tabletJustifyContent }) => tabletJustifyContent};
    align-items: ${({ tabletAlignItems }) => tabletAlignItems};
    gap: ${({ tabletGap }) => tabletGap};
    min-height: ${({ tabletMinHeight }) => tabletMinHeight};
    padding: ${({ tabletPadding }) => tabletPadding ?? '0 4vw'};
  }

  @media (max-width: ${breakpoints.screenSM}) {
    flex-direction: ${({ columnReverse, mobileColumn }) => (columnReverse ? 'column-reverse' : mobileColumn ? 'column' : 'row')};
    justify-content: ${({ mobileJustifyContent }) => mobileJustifyContent};
    align-items: ${({ mobileAlignItems }) => mobileAlignItems};
    gap: ${({ mobileGap }) => mobileGap};
    min-height: ${({ mobileMinHeight }) => mobileMinHeight};
    padding: ${({ mobilePadding }) => mobilePadding ?? '0 6vw'};
  }
`

export const Section = styled.div`
  padding: ${({ padding }) => padding ?? '5.5% 0'};
  margin-top: ${({ isCoverSection }) => (isCoverSection ? 'var(--header-height)' : '0')};
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'left')};
  background-color: ${({ theme, bgColor }) => bgColor ?? theme.backgroundPrimary};

  @media (max-width: ${breakpoints.screenLG}) {
    padding: ${({ tabletPadding }) => tabletPadding ?? '5.5% 0'};
  }

  @media (max-width: ${breakpoints.screenSM}) {
    padding: ${({ mobilePadding }) => mobilePadding ?? '10% 0'};
  }
`
