exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-catalogs-js": () => import("./../../../src/pages/catalogs.js" /* webpackChunkName: "component---src-pages-catalogs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-factory-tour-cup-production-js": () => import("./../../../src/pages/factory-tour/cup-production.js" /* webpackChunkName: "component---src-pages-factory-tour-cup-production-js" */),
  "component---src-pages-factory-tour-decal-application-or-base-mark-printing-js": () => import("./../../../src/pages/factory-tour/decal-application-or-base-mark-printing.js" /* webpackChunkName: "component---src-pages-factory-tour-decal-application-or-base-mark-printing-js" */),
  "component---src-pages-factory-tour-glazing-and-glaze-firing-js": () => import("./../../../src/pages/factory-tour/glazing-and-glaze-firing.js" /* webpackChunkName: "component---src-pages-factory-tour-glazing-and-glaze-firing-js" */),
  "component---src-pages-factory-tour-js": () => import("./../../../src/pages/factory-tour.js" /* webpackChunkName: "component---src-pages-factory-tour-js" */),
  "component---src-pages-factory-tour-mold-making-js": () => import("./../../../src/pages/factory-tour/mold-making.js" /* webpackChunkName: "component---src-pages-factory-tour-mold-making-js" */),
  "component---src-pages-factory-tour-quality-inspection-js": () => import("./../../../src/pages/factory-tour/quality-inspection.js" /* webpackChunkName: "component---src-pages-factory-tour-quality-inspection-js" */),
  "component---src-pages-factory-tour-quality-inspection-packaging-storage-js": () => import("./../../../src/pages/factory-tour/quality-inspection-packaging-storage.js" /* webpackChunkName: "component---src-pages-factory-tour-quality-inspection-packaging-storage-js" */),
  "component---src-pages-factory-tour-raw-materials-js": () => import("./../../../src/pages/factory-tour/raw-materials.js" /* webpackChunkName: "component---src-pages-factory-tour-raw-materials-js" */),
  "component---src-pages-factory-tour-rolling-process-production-js": () => import("./../../../src/pages/factory-tour/rolling-process-production.js" /* webpackChunkName: "component---src-pages-factory-tour-rolling-process-production-js" */),
  "component---src-pages-factory-tour-slip-casting-js": () => import("./../../../src/pages/factory-tour/slip-casting.js" /* webpackChunkName: "component---src-pages-factory-tour-slip-casting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-tableware-js": () => import("./../../../src/pages/tableware.js" /* webpackChunkName: "component---src-pages-tableware-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

